import './index.scss';
import Footer from '@/components/Footer/Footer';
import Header from '@/components/Header/Header';
import PageContentWidthTitle from '@/components/PageContentWidthTitle/PageContentWidthTitle';
import QuestionItem from './components/QuestionItem'
import { useEffect,useState } from 'react';
import {getQuestions} from '@/api';





export default function QuestionPage(){

    const [list, setList] = useState([])

    useEffect(() => {
        getQuestions().then(res => {
            setList(res.data);
        })
    },[])
    return (
        <div className="question_page">
            <Header />
            <div className="split-line">
                 <PageContentWidthTitle title='常见问题'>
                    <div className="question_list">
                        {list.map(it => (<QuestionItem key={it.id} question={it.question} answer={it.answer} />))}
                    </div>
                 </PageContentWidthTitle>
            </div>
            <Footer hideForm />
        </div>
    )

}