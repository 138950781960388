import { useRoutes } from 'react-router-dom';
import { Suspense, useEffect } from 'react';


import Home from '../views/Home/index'
import List from '../views/List/index'
import Search from '../views/Search/index'
import Case from '../views/Case/index'
import Production from '../views/Production/index'
import Exhibition from '../views/Exhibition/index'
import Information from '../views/Information/index'
import Customize from '../views/Customize/index'
import Address from '../views/Address/index'
import Question from '../views/Question/index'
import Introduce from '../views/Introduce';
import Detail from '../views/Detail/Detail'


const routes = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/list',
    element: <List />
  },
  {
    path: '/search',
    element: <Search />
  },
  {
    path: '/case',
    element: <Case />
  },
  {
    path: '/production',
    element: <Production />
  },
  {
    path: '/exhibition',
    element: <Exhibition />
  },
  {
    path: '/information',
    element: <Information />
  },
  {
    path: '/customize',
    element: <Customize />
  },
  {
    path: '/address',
    element: <Address />
  },
  {
    path: '/question',
    element: <Question />
  },
  {
    path: '/brand-introduce',
    element: <Introduce />
  },
  {
    path: '/factory-introduce',
    element: <Introduce />
  },
  {
    path: '/law-term',
    element: <Introduce />
  },
  {
    path: '/law-statement',
    element: <Introduce />
  },
  {
    path: '/detail',
    element: <Detail />
  },
]

const Router = () => {

  useEffect(() => {
    if (location.pathname !== '/detail') {
      localStorage.setItem('path', location.pathname + location.search);
    }
  }, [location.pathname]);

  return (
    <Suspense>
      {useRoutes(routes)}
    </Suspense>
  )
}


const RouterBeforeEach = ({ children }) => {

  return children
};

export { Router, RouterBeforeEach }