import './index.scss';

import ServiceCenter from '../ServiceCenter/ServiceCenter';
import wxIcon from '@/assets/img/ic_weixin_lianxiwomen.png';
import { getConfig } from '@/api';
import { useEffect, useState } from 'react';







export default function NeedHelpContent() {

    const [code, setCode] = useState('')

    useEffect(() => {
        getConfig().then(res => {
            res.data.map(it => {
                if (it.name === "wechat_qrcode") {
                    setCode(it.value);
                }
                return 0;
            })
        })
    }, [])

    return (
        <div className="need_help_content">
            <p>如果您有任何问题都可以联系我们</p>
            <p>我们会为您提供更专业的服务</p>

            <ServiceCenter />

            <div className="code_block">
                <div className="code_type">
                    <img src={wxIcon} className="ic" alt="" />
                    微信公众号
                </div>

                <div className="code_wrap">
                    <img src={code} className="code_img" alt="" />
                    <div className="code_description">
                        请用微信扫描二维码添加关注
                    </div>
                </div>
            </div>
        </div>
    )
}