import './index.scss';
import Footer from '@/components/Footer/Footer';
import Header from '@/components/Header/Header';
import BrandInformation from '@/components/BrandInformation/BrandInformation';
import ProductIntrodution from '@/components/ProductIntrodution/ProductIntrodution';

import { useNavigate } from 'react-router-dom';
import { useRef, useEffect, useState } from 'react';

import { getProducts, getCarousel } from '@/api';




export default function CasePage() {
  const navigate = useNavigate();
  const scrollView = useRef();
  const [products, setProducts] = useState([]);
  const [imgs, setImgs] = useState([])





  useEffect(() => {

    getProducts({ page_size: 6 }).then(res => {
      setProducts(res.data)
    });

    getCarousel({ show_location: 'product' }).then(res => {
      // console.log(res);
      setImgs(res.data);
    })
  }, []);


  function handleClick({ content }) {
    if (content) {
      localStorage.setItem('content', content);
      navigate('/detail');
    }
  }

  return (
    <div className="case_page">
      <Header />


      {products.map((item, i) => {
        if (i % 3 === 0) {
          return (<BrandInformation
            key={i}
            img={item.image}
            content={item.content}
            title={item.name}
            text={item.desc}
            cateId={item.category_id}
          />)
        } else if (i % 3 === 1) {
          return (<ProductIntrodution
            key={i}
            title={item.name}
            img={item.image}
            text={item.desc}
            content={item.content}
            cateId={item.category_id}
          />)
        } else {
          return (
            <div key={i}>
              <ProductIntrodution
                key={i}
                right={true}
                title={item.name}
                img={item.image}
                text={item.desc}
                content={item.content}
                cateId={item.category_id}
              />
              <div key={i * Math.random()} style={{ marginTop: '1.36rem' }} />
            </div>
          )
        }
      })}

      <div className="productions_shown">
        <h4 className="title">欢迎联系我们了解更多产品信息</h4>
        <div className="look_all" onClick={function () {
          navigate('/list');
        }}>查看所有产品</div>

        {imgs.length ? <div className="pic_wall" ref={scrollView}>
          {imgs.map((it) => {
            return (<img
              key={it.id}
              src={it.image}
              onClick={() => handleClick(it)}
              className='pic_item'
              alt="" />)
          })}
        </div>
          : null
        }
      </div>


      <Footer />
    </div>
  )
}