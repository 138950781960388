import './index.scss';
import { useState,useImperativeHandle, forwardRef } from 'react';

import SuccssIcon from '@/assets/img/ic_chenggong.png';
import FailIcon from '@/assets/img/ic_shibai.png';


function Alert({type='success',message='成功',delay=2000},ref){
    const [show, setShow] = useState(false)


    useImperativeHandle(ref,() => ({
        show:() => {
            if(show) return;
            setShow(true);

            setTimeout(() => {
                setShow(false);
            },delay)
        }
    }))

    return (
        <div className={"rj_alert " + (show ? 'show' : '')}>
            <img src={type === 'success' ? SuccssIcon : type === 'fail' ? FailIcon : SuccssIcon} className="alert_ic" alt="" />
            <div className='alert_msg'>{message}</div>
        </div>
    )
}

export default  forwardRef(Alert)