
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

export function useScrollToTopHook() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}



export function usePageQuery(getListApi, getCateApi) {
    const [list, setList] = useState([])
    const [cateList, setCateList] = useState([])
    const [params, setParams] = useState({
        category_id: '',
        sort: '',
        page: 1
    });

    useEffect(() => {
        const query = { ...params }
        if (!params.sort) {
            delete query.sort
        }
        getListApi(query).then(res => {
            if (params.page > 1) {
                setList([...list, ...res.data])
                return;
            }
            setList(res.data);
            localStorage.setItem('length', list.length);
        });
    }, [params])

    useEffect(() => {
        getCateApi().then(res => {
            setCateList(res.data)
        })
    }, [])




    function onSearchChange(cateId, sort) {
        setParams({
            ...params,
            category_id: cateId.map(it => it.id).join(','),
            sort,
            page: 1
        })
    }



    return {
        list,
        cateList,
        onSearchChange,
        setParams,
        params
    }
}



