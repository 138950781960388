import './index.scss';
import { useRef } from 'react';
import closeIcon from '@/assets/img/ic_guanbi.png';


export default function Drawer(props){
    const placement = props.placement || 'left';
    const open = props.open || false;

    const wrap = useRef(null)

    function handleClose(){
        props.onClose && props.onClose()
    }
  
    return (
        <div ref={wrap} className={open ? "open ruijia_drawer_wrapper" : "ruijia_drawer_wrapper"} onClick={handleClose}>
            <div className={"ruijia_drawer_content " + placement} onClick={e => e.stopPropagation()}>
                <div className="ruijia_drawer_header">
                    <img src={closeIcon} className="close_icon" onClick={handleClose} alt="" />
                    <span className='title'>{props.title}</span>
                </div>
                <div className="ruijia_drawer_body">
                    {props.children}
                </div>
            </div>
        </div>
    )
}