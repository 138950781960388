import React from 'react'
import { getConfig } from '@/api';
import { useEffect, useState } from 'react';

import Header from '../../components/Header/Header';
import Footer from '@/components/Footer/Footer';

import { useLocation } from 'react-router-dom';

import style from './index.module.scss';

export default function Introduce() {
    const [content, setContent] = useState('');
    const location = useLocation();
    useEffect(() => {
        getConfig().then(res => {
            res.data.map(it => {
                if (it.name === "company_intro" && location.pathname === '/factory-introduce') {
                    setContent(it.value);
                } else if (it.name === "brand_intro" && location.pathname === '/brand-introduce') {
                    setContent(it.value);
                } else if (it.name === "clause_law" && location.pathname === '/law-term') {
                    setContent(it.value);
                } else if (it.name === "declaration_law" && location.pathname === '/law-statement') {
                    setContent(it.value);
                }
                return 0;
            })
        })
    }, [location])

    return (
        <div>
            <Header />

            <div className={style.content} style={{ textAlign: "center" }}>
                <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
            <Footer></Footer>
        </div>
    )
}
