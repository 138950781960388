import './index.scss';
import { useState } from 'react';



export default function SearchWidget({onSearch = () => (void 0),}){
    const [keywords, setKeywords] = useState('');

    return (
        <div className="search_widget">
                <input 
                type="text" 
                value={keywords} 
                className="search_input" 
                placeholder='搜索产品名'
                onChange={function(e){
                   setKeywords(e.target.value);
                }} />

                <div className="search_btn" onClick={() => {
                    if(!keywords) return;
                    onSearch(keywords);
                }}>
                    搜索
                </div>
        </div>
    )
}