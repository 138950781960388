export function verifyPhone(phone) {
	return /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(phone);
}


export function navigateBlank(url) {
	// 获取绝对路径
	const hrefStr = window.location.href;
	// 获取相对路径
	const urlParams = new URL(hrefStr);
	// console.log(urlParams, pathname);
	// 获取根路径
	const rootPath = urlParams.origin;
	// 在新标签页面打开绝对路径
	window.open(`${rootPath}${url}`, '_blank');
}


// 生成菜单数据
export function mapMenuData(reqData) {

	return reqData.map((it) => {
		return {
			name: it.name,
			id: it.id,
			link: it.children ? null : `/list?id=${it.pid || it.id}`,
			children: it.children ? mapMenuData(it.children) : null
		}
	})
}


export function throttle(fn, immediate = 200) {
	let timer = null;
	return function () {
		const args = arguments;
		if (!timer) {
			timer = setTimeout(() => {
				fn.apply(this, args)
				clearTimeout(timer);
				timer = null;
			}, immediate);
		}
	}
}