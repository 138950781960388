import './index.scss';
import arrowDown from '@/assets/img/ic_xialajiantou.png';
import deleteIcon from '@/assets/img/ic_guanbi_phone.png';
import { useEffect, useState } from 'react';
import CheckBoxGroup from '../CheckBoxGroup/CheckBoxGroup';
import { useSearchParams } from "react-router-dom";





const sortValues = [
    {
        value: '',
        label: '默认'
    },
    {
        value: 'asc',
        label: '时间升序'
    },
    {
        value: 'desc',
        label: '时间降序'
    },
]

export default function SearchBar({
    productTypes = [],
    onChange = () => (void 0),
}) {
    const [leftExpand, setLeftExpand] = useState(false);
    const [rightExpand, setRightExpand] = useState(false);
    const [productValue, setProductValue] = useState([]);
    const [sortCondition, setSortCondition] = useState('');

    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');

    useEffect(() => {

        if (productTypes.length && id) {
            const searchType = productTypes.filter(it => it.id === +id)[0];

            if (searchType && productValue.filter(it => it.id === searchType.id).length === 0) {

                setProductValue([searchType]);
                onChange([searchType], sortCondition);
            }
        }
    }, [id, productTypes])


    return (
        <div className={leftExpand ? 'search_bar_wraper left_expand' : rightExpand ? 'search_bar_wraper right_expand' : 'search_bar_wraper'}>
            <div className="search_bar">
                <div
                    className={leftExpand ? 'left_search search_widget expand' : 'left_search search_widget'}
                    onClick={() => {
                        setLeftExpand(!leftExpand)
                        if (rightExpand) {
                            setRightExpand(!rightExpand)
                        }
                    }}
                >
                    所有产品
                    <img src={arrowDown} className="arrow_ic" alt="" />
                </div>
                <div className={"right_search search_widget"}>


                    <span className="del_btn" onClick={() => {
                        setProductValue([]);
                        setSortCondition('');
                        onChange([], '');
                    }}>删除</span>


                    <span className="split_line"></span>


                    <div
                        className={rightExpand ? 'sort_widget expand' : 'sort_widget'}
                    >
                        <div className='sort_label'
                            onClick={() => {
                                setRightExpand(!rightExpand)
                                if (leftExpand) {
                                    setLeftExpand(!leftExpand);
                                }
                            }}>
                            <div className="name">按条件排列</div>
                            <div className="value">{sortValues.filter(it => it.value === sortCondition)[0].label}</div>
                        </div>
                        <img src={arrowDown} className="arrow_ic" alt="" />
                        <div className="select_wrap">
                            {sortValues.map((it, i) => {
                                return (
                                    <div
                                        key={i}
                                        className={sortCondition === it.value ? 'select_item active' : 'select_item'}
                                        onClick={function (e) {
                                            // e.stopPropagation();
                                            setRightExpand(!rightExpand);
                                            setSortCondition(it.value)
                                            onChange(productValue, it.value);
                                        }}
                                    >{it.label}</div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <CheckBoxGroup options={productTypes} value={productValue} onChange={value => {
                setProductValue(value);
                onChange(value, sortCondition);
            }} />

            <div className="checked_options">
                {productValue.map((it, i) => {

                    return (
                        <span className="checked_item" key={i}>
                            {it.name}
                            <img
                                src={deleteIcon}
                                className="del_ic"
                                alt=""
                                onClick={function () {
                                    const value = productValue.filter((it, index) => {
                                        return i !== index;
                                    });
                                    onChange(value, sortCondition);
                                    setProductValue(value);
                                }}
                            />
                        </span>
                    )
                })}
            </div>
        </div>)
}