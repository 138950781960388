import './index.scss';
import Footer from '@/components/Footer/Footer';
import Header from '@/components/Header/Header';
import SearchBar from '@/components/SearchBar/SearchBar';
import BrandInformation from '@/components/BrandInformation/BrandInformation';
import NoResult from '../../components/NoResult/NoResult';
import { Row, Col } from 'antd';
import { getNewsList, getNewsCate } from '@/api';
import { usePageQuery } from '@/hooks';




export default function CasePage() {
    const { list, cateList, onSearchChange, setParams, params } = usePageQuery(getNewsList, getNewsCate);

    return (
        <div className="case_page">
            <Header />
            <SearchBar productTypes={cateList} onChange={onSearchChange} />
            {/* 品牌咨询 */}
            <div className="case_list">
                <Row gutter={20}>
                    {list.map(it => {
                        return (<Col className="column" md={12} key={it.id}>
                            <BrandInformation
                                key={it.id}
                                link={it.jump_url}
                                img={it.image}
                                title={it.title}
                                text={it.desc}
                                content={it.content}
                            />
                        </Col>)
                    })}
                </Row>
            </div>
            {list.length === 0 && <NoResult />}
            <Footer length={list.length} onReachBottom={() => {
                if (list.length === params.page * 12) {
                    setParams({
                        ...params,
                        page: params.page + 1
                    })
                }
            }} />
        </div>
    )
}