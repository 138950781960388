import CheckBox from "./CheckBox/CheckBox";
import './index.scss'




CheckBoxGroup.CheckBox = CheckBox;

export default function CheckBoxGroup({
    options = [],
    value = [],
    onChange = () => (void 0)
}) {




    const selecValues = value.map(it => it.id);






    function checkedValue(item) {

        const i = value.findIndex(it => it.id === item.id);
        if (i === -1) {
            onChange([...value, item])
        } else {
            onChange(value.filter(it => it.id !== item.id));
        }


    }
    return (
        <div className="rj_check_box_grop">
            {options.map((it, i) => {
                return (
                    <CheckBox key={i} checked={selecValues.filter(item => it.id === +item).length === 1} onChange={() => {
                        checkedValue(it)
                    }}>
                        <div className="checked_label">
                            {it.name}
                            <span className="number_box">({it.total})</span>
                        </div>
                    </CheckBox>
                )
            })
            }
        </div>
    )
}

