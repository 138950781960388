import './index.scss';

import { useState, useRef } from 'react';
import SelectCity from '../SelectCity/SelectCity';
import ServiceCenter from '../ServiceCenter/ServiceCenter';
import { submitContact } from '@/api';
import { verifyPhone } from '@/utils';
import Alert from '@/components/Alert/Alert';
const defaultData = {
    name: '',
    mobile: '',
    address: '北京市 北京市',
};
export default function LinkUs() {

    const [alert, setAlert] = useState({
        type: 'success',
        message: '',
    });
    const alertRef = useRef()
    const [data, setData] = useState(defaultData)

    function handleSubmit() {
        console.log(data);
        if (!data.name) {
            setAlert({
                type: 'fail',
                message: '请填写您的名字'
            });

            alertRef.current.show();
            return;
        } else if (!data.mobile) {
            setAlert({
                type: 'fail',
                message: '请填写您的手机号'
            });

            alertRef.current.show();
            return;
        } else if (!verifyPhone(data.mobile)) {
            setAlert({
                type: 'fail',
                message: '手机号格式不正确'
            });

            alertRef.current.show();
            return;
        }
        submitContact(data).then(() => {
            setAlert({
                type: 'success',
                message: '提交成功，感谢联系！'
            });

            setData({ ...defaultData })
            alertRef.current.show();
        })
    }
    return (
        <div className="link_us">

            <Alert ref={alertRef} type={alert.type} message={alert.message} />
            <div className="link_form">
                <div className="form_label">输入您的电话 <span className='xing'>*</span></div>
                <div className="form_widget phone">
                    <span className="quhao">+86</span>
                    <input value={data.mobile} onChange={e => {
                        setData({
                            ...data,
                            mobile: e.target.value
                        })
                    }} type="text" maxLength={11} className='input' />
                </div>
                <div className="form_label">输入您的姓名 <span className='xing'>*</span></div>
                <div className="form_widget name">
                    <input value={data.name} onChange={e => {

                        setData({
                            ...data,
                            name: e.target.value
                        })
                    }} type="text" maxLength={20} className='input' />
                </div>

                <div className="form_label">选择您所在的地区 <span className='xing'>*</span></div>
                <div className="form_widget area">
                    <SelectCity onChange={e => {
                        setData({
                            ...data,
                            address: e
                        })
                    }} />
                </div>
                <div className="form_label explanation"><span className='xing'>*</span> 必填项目 </div>

                <div className="submit_btn" onClick={handleSubmit}>提交内容</div>

                <div className="after_submit">
                    提交内容后：
                    <ul className='prompt_list'>
                        <li><span className="dot"></span>将有专员负责为您提供专业的服务信息</li>
                        <li><span className="dot"></span>您所提交的信息我们会做好保密处理</li>
                    </ul>
                </div>
            </div>

            <ServiceCenter />

        </div>
    )
}