import axios from 'axios';


const request = axios.create({
    baseURL: 'https://hub-admin.roocolor.cn/api/'
})


request.interceptors.response.use(function(res){


    return res.data;
})















export default request;