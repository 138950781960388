import './index.scss';
import expandIcon from '@/assets/img/ic_mulu_xinkaiye.png';
import retractIcon from '@/assets/img/ic_mulu_xinkaiye_01.png';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';


export default function Tree({ data = [], clickItem, type }) {
    const navigate = useNavigate();

    const [itemState, setItemState] = useState(new Array(data.length).fill(false))

    function handleExpand(it, i, e) {
        e.stopPropagation();
        if (it.children && it.children.length > 0) {
            const arr = new Array(data.length).fill(false);
            arr[i] = !itemState[i]
            setItemState(arr)
        }
    };


    return (
        <ul className="rj_tree">
            {data.map((it, i) => {
                return (
                    <li
                        onClick={function (e) {
                            e.stopPropagation();
                            if (it.link) {
                                navigate(it.link);
                            } else if (it.href) {
                                window.location.href = it.href;
                            } else if (it.children && it.children.length) {
                                handleExpand(it, i, e);
                                return;
                            }

                            clickItem();
                        }}
                        key={i}
                        className={itemState[i] ? "rj_tree_item  expand" : "rj_tree_item"}>
                        <span>{it.name}</span>

                        {it.children || type !== 'child_tree' ? (
                            <>
                                <img src={expandIcon} alt="" className="icon1" />
                                <img src={retractIcon} alt="" className="icon2" />
                            </>
                        ) : (<span></span>)}

                        {it.children && <Tree type='child_tree' data={it.children} clickItem={clickItem} />}
                    </li>
                )
            })}
        </ul>
    )
}