import './index.scss';

import { getConfig } from '@/api';
import { useEffect, useState } from 'react';




export default function ServiceCenter() {
    const [hotline, setHotline] = useState('');
    const [investmentLine, setInvestmentLine] = useState('');

    useEffect(() => {
        getConfig().then(res => {
            res.data.map(it => {
                if (it.name === "service_hotline") {
                    setHotline(it.value);
                } else if (it.name === "investment_promotion_hotline") {
                    setInvestmentLine(it.value);
                }
            })
        })
    }, [])

    return (
        <div className="service_center">
            <div className="title">客户服务中心</div>
            <p className="hot_link">服务热线 <a href={`tel:${hotline}`}>{hotline}</a></p>
            <p className="hot_link">招商加盟热线<a href={`tel:${investmentLine}`}>{investmentLine}</a></p>
            <span className="service_time">周一至周五：9:00-18:00</span>
        </div>
    )
}