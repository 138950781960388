import './index.scss';




export default function QuestionItem({ question, answer }) {
    return (
        <div className="question_item">
            <div className="title" > {question}</div>
            <div className="answer" dangerouslySetInnerHTML={{ __html: answer }} />
        </div>
    )
}