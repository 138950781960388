import './index.scss';



export default function PageContentWidthTitle({title = '',children = null}){

        return (
            <div className="page_content_width_title">
                <div className='page_title'>{title}</div>
                <div className="page_content">
                    {children}
                </div>
            </div>
        )
}