import './index.scss';








export default function NoResult({children}){


    return (
        <div className="no_result">
            {children ||'没有符合条件的结果~'}
        </div>
    )
}