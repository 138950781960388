import styles from './index.module.scss';
import './index.scss'

import FooterNav from './components/FooterNav';
import dyIcon from '@/assets/img/ic_douyin_web.png';
import wxIcon from '@/assets/img/ic_weixin_web.png';
import xhsIcon from '@/assets/img/ic_xiaohongshu_web.png';
import dyPhoneIcon from '@/assets/img/ic_douyin_phone.png';
import wxPhoneIcon from '@/assets/img/ic_weixin_phone.png';
import xhsPhoneIcon from '@/assets/img/ic_xiaohongshu_phone.png';
import Alert from '@/components/Alert/Alert';

import SelectCity from '../SelectCity/SelectCity';
import { useState, useRef, useEffect } from 'react';
import LinkUs from '@/components/LinkUs/LinkUs';
import Drawer from '@/components/Drawer/Drawer';

import { submitContact } from '@/api';
import { verifyPhone } from '@/utils';

import { Popover, BackTop } from 'antd';

import { getConfig } from '@/api';
import { useLocation } from 'react-router-dom';

import { throttle } from '../../utils';


const brandNav = [
    {
        name: '品牌介绍',
        link: '/brand-introduce'
    },
    {
        name: '产品系列',
        link: '/production',
    },
    {
        name: '定制方案',
        link: '/customize',
    },
    {
        name: '案例展示',
        link: '/case',
    },
    {
        name: '展厅效果',
        link: '/exhibition',
    },
]

const serviceNav = [
    {
        name: '常见问题',
        link: '/question'
    },
    {
        name: '地图地址',
        link: '/address'
    },
    {
        name: '联系我们',
        href: '#contactUs',
    }
]
const lawNav = [
    // {
    //     name: '法律条款',
    //     link: '/law-term'
    // },
    // {
    //     name: '法律声明',
    //     link: '/law-statement'
    // }
]
const defaultData = {
    name: '',
    mobile: '',
    address: '北京市 北京市',
};
export default function Footer({ hideForm = false, onReachBottom = () => { }, length }) {
    const [alert, setAlert] = useState({
        type: 'success',
        message: '',
    });
    const alertRef = useRef();
    const footerRef = useRef();
    const [linkOpen, setLinkOpen] = useState(false)
    const [data, setData] = useState(defaultData);
    const [qr_code, setQr_code] = useState('');
    const [titok_link, setTitok_link] = useState('');
    const [red_book, setRed_book] = useState('');

    useEffect(() => {

        getConfig().then(res => {
            res.data.map(it => {
                if (it.name === "wechat_qrcode") {
                    setQr_code(it.value);
                } else if (it.name === "trill_qrcode") {
                    setTitok_link(it.value);
                } else if (it.name === "little_red_book_qrcode") {
                    setRed_book(it.value);
                }
                return 0
            })
        });

        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 20)
    }, []);


    function handleSubmit() {
        if (!data.name) {
            setAlert({
                type: 'fail',
                message: '请填写您的名字'
            });

            alertRef.current.show();
            return;
        } else if (!data.mobile) {
            setAlert({
                type: 'fail',
                message: '请填写您的手机号'
            });

            alertRef.current.show();
            return;
        } else if (!verifyPhone(data.mobile)) {
            setAlert({
                type: 'fail',
                message: '手机号格式不正确'
            });

            alertRef.current.show();
            return;
        }
        submitContact(data).then(() => {
            setAlert({
                type: 'success',
                message: '提交成功，感谢联系！'
            });

            setData({ ...defaultData })
            alertRef.current.show();
        })
    };


    function visitTitok() {
        window.open(titok_link, 'blank')
    }

    function visitRedBook() {
        window.open(red_book, 'blank');
    };






    useEffect(() => {
        const lazyLoad = throttle(function () {
            let clietH = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
            var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            if (clietH + scrollTop - footerRef.current.offsetTop > 100) {
                onReachBottom();
            }
        })
        window.addEventListener("scroll", lazyLoad);
        return () => {
            window.removeEventListener('scroll', lazyLoad)
        }
    }, [length])

    return (
        <footer className='footer' ref={footerRef}>
            <Alert ref={alertRef} type={alert.type} message={alert.message} />
            <Drawer open={linkOpen} onClose={() => setLinkOpen(false)} title="联系我们" placement="right">
                <LinkUs />
            </Drawer>
            {/* 合作加盟 */}
            {
                hideForm ? null : (
                    <div className={'split-line ' + styles.join_us}>
                        <h4 className={styles.title} id="contactUs">JOIN TOGETHER</h4>
                        <p className={styles.text}>合作加盟</p>

                        <div className="submit_form">
                            <div className="form_item">
                                <span className="labels text">
                                    输入您的姓名
                                </span>
                                <input value={data.name} onChange={e => {

                                    setData({
                                        ...data,
                                        name: e.target.value
                                    })
                                }} className='form_wiget input' type="text" />
                            </div>
                            <div className="form_item">
                                <span className="labels text">
                                    输入您的电话
                                </span>
                                <input value={data.mobile} onChange={e => {
                                    setData({
                                        ...data,
                                        mobile: e.target.value
                                    })
                                }} className='form_wiget input' type="text" />
                            </div>
                            <div className="form_item" style={{ border: 'none' }}>
                                <span className="labels text">
                                    选择您所在的地区
                                </span>
                                <div className='form_wiget'>
                                    <SelectCity onChange={e => {
                                        setData({
                                            ...data,
                                            address: e
                                        })
                                    }} />
                                </div>
                            </div>

                            <div className="between btn_wrap">
                                <button className='btn primay_btn' onClick={handleSubmit}>提交内容</button>
                                <button className='btn' onClick={() => setLinkOpen(true)}>电话联系</button>
                            </div>
                        </div>
                    </div>
                )
            }


            {/* 页脚导航 */}

            <div className="footer_navs split-line">
                <FooterNav title="关于品牌" list={brandNav} />
                <FooterNav title="关于服务" list={serviceNav} clickLink={() => setLinkOpen(true)} />
                <FooterNav title="关于我们" list={[]}>
                    <div className='link_icons'>
                        <Popover placement='top' content={
                            <img src={qr_code} style={{ width: '2rem', height: '2rem' }} />
                        }>
                            <img src={wxIcon} alt="" className="link_ic hidden-xs" />
                        </Popover>
                        <img src={xhsIcon} alt="" className="link_ic hidden-xs" onClick={visitRedBook} />
                        <img src={dyIcon} alt="" className="link_ic hidden-xs" onClick={visitTitok} />
                        <Popover placement='top' content={
                            <img src={qr_code} style={{ width: '2rem', height: '2rem' }} />
                        }>
                            <img src={wxPhoneIcon} alt="" className="link_ic visible-xs" />
                        </Popover>
                        <img src={xhsPhoneIcon} alt="" className="link_ic visible-xs" onClick={visitRedBook} />
                        <img src={dyPhoneIcon} alt="" className="link_ic visible-xs" onClick={visitTitok} />
                    </div>
                </FooterNav>
            </div>

            <BackTop style={{ right: '40px', width: '44px', height: '44px', overflow: 'hidden' }}>
                <img src={require('@/assets/img/backtop.png')} width={44} height={44} />
            </BackTop>

            <div style={{
                textAlign: 'center',
                paddingBottom: 20
            }}>青岛瑞加漆业有限公司 | <a onClick={() => window.open('https://beian.miit.gov.cn', 'blank')}>鲁ICP备19019826号-4</a> | © Copyright © 2018-2023 青岛瑞加漆业有限公司 版权所有</div>
        </footer>
    )
}