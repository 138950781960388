import './index.scss';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import arrowDownIcon from '@/assets/img/ic_jiantou_weizhankai.png';


export default function FooterNav(props) {
    const navigate = useNavigate();
    const [expand, setExpand] = useState(false)

    return (
        <div className="footer_nav">
            <h4 className="title" onClick={() => {
                setExpand(!expand)
            }}>
                {props.title}
                {!!props.list.length && (expand ? <img src={arrowDownIcon} className="arrow_ic top visible-xs" alt="" /> : <img src={arrowDownIcon} className="arrow_ic visible-xs" alt="" />)}
                {props.children && <div className="children">
                    {props.children}
                </div>
                }
            </h4>
            <ul className={expand ? "nav_block expand" : "nav_block"}>
                {props.list.map((it, i) => {
                    return (<li
                        onClick={function () {
                            if (it.link) {
                                navigate(it.link);
                            }
                            if (it.href) {
                                props.clickLink();
                            }
                        }}
                        className="nav_item"
                        key={i}>{it.name}</li>)
                })}
            </ul>
        </div>
    )
}