import './index.scss';
import xiajiantouIcon from '@/assets/img/ic_jiantou_dizhi.png';

import { useState } from 'react';

import { cityData } from '@/data/index.js';

export default function SelectCity({ onChange }) {
    const [province, setProvince] = useState('北京市');
    const [showProvince, setShowProvince] = useState(false);

    const [city, setCity] = useState('北京市');
    const [showCity, setShowCity] = useState(false);
    const [cityList, setCityList] = useState([{ name: '北京市' }])

    /**
     * 切换省份
     * @param {*} e 
     */
    const changeProvince = function (province) {
        setProvince(province.name);
        setCity(province.city[0].name)
        setCityList(province.city);
        onChange(province.name + ' ' + province.city[0].name);
    }

    const changeCity = function (city) {
        setCity(city);
        onChange(province + ' ' + city);
    }

    return (
        <div className="city_selector">
            <div className="province_select select_widget" onClick={() => setShowProvince(!showProvince)}>
                <span className='name'>{province}</span>
                <img src={xiajiantouIcon} className="arrow_down" alt="" />
                <ul className={showProvince ? "list_box province_list" : "list_box province_list hidden"}>
                    {cityData.map((it, i) => {
                        return (
                            <li
                                onClick={function () {
                                    changeProvince(it)
                                }}
                                className={it.name === province ? 'active list_item' : 'list_item'}
                                key={i}>{it.name}</li>
                        )
                    })}
                </ul>
            </div>
            <div className="city_select select_widget" onClick={() => setShowCity(!showCity)}>
                <span className='name'>{city}</span>
                <img src={xiajiantouIcon} className="arrow_down" alt="" />
                <ul
                    className={showCity ? "list_box city_list" : "list_box city_list hidden"}>
                    {cityList.map((it, i) => {
                        return (
                            <li
                                onClick={function () {
                                    changeCity(it.name);
                                }}
                                className={it.name === city ? 'active list_item' : 'list_item'}
                                key={i}>{it.name}</li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}