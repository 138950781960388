import checkedIcon from '@/assets/img/ic_duoxuan_yixuan.png';
import uncheckIcon from '@/assets/img/ic_duoxuan_weixuan.png';
import './index.scss';



export default function CheckBox({checked = false,onChange = () => (void 0),children}){

    return (
        <div className="rj_check_box" onClick={() => {onChange()}}>
            <>
              {checked ? <img src={checkedIcon} className="check_box_ic" alt="" /> 
              :  <img src={uncheckIcon} className="check_box_ic" alt="" />}
            </>

            { children }
        </div>
    )
}