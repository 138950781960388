import React from 'react'
import { useEffect, useState } from 'react';

import Header from '../../components/Header/Header';
import Footer from '@/components/Footer/Footer';
import BreadCrumb from '../../components/BreadCrumb';

import style from './index.module.scss';


export default function Introduce() {
  const [content, setContent] = useState('');
  useEffect(() => {
    const content = localStorage.getItem('content');
    setContent(content)
  }, [])

  return (
    <div>
      <Header />
      <BreadCrumb />
      <div className={style.content} style={{ textAlign: "center" }}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
      <Footer></Footer>
    </div>
  )
}
