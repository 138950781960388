import request from '@/utils/request.js';


// 资讯列表
export function getNewsList(params) {
    return request.get('news/lists', { params })
}


// 资讯分类
export function getNewsCate() {
    return request.get('news/category');
}


// 产品列表
export function getProducts(params) {
    return request.get('product/lists', { params })
}

// 产品分类
export function getProductCate() {
    return request.get('product/category');
}

// 获取问答
export function getQuestions() {
    return request.get('question/lists');
}

// 提交联系方式
export function submitContact(data) {
    return request.post('system/submit', data);
}

// 案例列表
export function getCaseList(params) {
    return request.get('cases/lists', { params })
}

// 案例分类
export function getCaseCate() {
    return request.get('cases/category');
}


// 展厅列表
export function getExhibitionList(params) {
    return request.get('exhibition/lists', { params })
}

// 展厅分类
export function getExhibitionCate() {
    return request.get('exhibition/category');
}

// 获取配置
export function getConfig() {
    return request.get('system/getConfig')
}


// 获取轮播
/**
 * 
 * @param {*} params show_location /home 首页  /cases 案例  /product 产品   /exhibition 展厅
 * @returns 
 */
export function getCarousel(params) {
    return request.get('system/getSlide', { params });
}

// 定制方案
export function getCustomize() {
    return request.get('Customizedscheme/lists')
}