import './index.scss';
import Footer from '@/components/Footer/Footer';
import Header from '@/components/Header/Header';
import BrandInformation from '@/components/BrandInformation/BrandInformation';
import { getCustomize } from '@/api';
import { useState, useEffect } from 'react';






export default function CustomizePage() {

    const [list, setList] = useState([]);

    useEffect(() => {
        getCustomize().then(res => {
            setList(res.data);
        })
    }, [])

    return (
        <div className="customize_page">
            <Header />

            <div className="customize_list">
                {list.map(it => {
                    return (<div className="customize_item" key={it.id}>
                        <BrandInformation content={it.content} title={it.title} text={it.desc} img={it.image} />
                    </div>)
                })}
            </div>


            <Footer />
        </div>
    )
}