import './index.scss';
import Footer from '@/components/Footer/Footer';
import Header from '@/components/Header/Header';
import SearchWidget from './components/SearchWidget/SearchWidget';
import { useState, useEffect } from 'react';
import ProductionItem from '@/components/ProductionItem/ProductionItem';
import { getProducts } from '@/api';



export default function SearchPage() {
    const [hasSearch, setHasSearch] = useState(false);
    const [list, setList] = useState([]);
    const [keyword, setKeyword] = useState('')

    useEffect(() => {
        getProducts({ keyword }).then(res => {
            setList(res.data);
        })
    }, [keyword])
    return (
        <div className="search_page">
            <Header />
            <div className="search_area split-line">
                <SearchWidget onSearch={function (e) {
                    setKeyword(e);
                    setHasSearch(true);
                }} />
            </div>

            <div className="search_result">

                {(hasSearch && list.length === 0) ? <div className="no_result">没有搜索到产品</div> : null}

                {(hasSearch && list.length > 0) ? (<>
                    <div className="result_count">
                        搜索结果：{list.length}个产品
                    </div>
                    <div className="search_product_list">
                        <div className="row">
                            {list.map(item => {
                                return (
                                    <div className="col-md-4" key={item.id}>
                                        <ProductionItem
                                            title={item.name}
                                            img={item.image}
                                            text={item.desc}
                                            content={item.content}
                                        />
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                </>) : null}
            </div>



            <Footer />
        </div>
    )
}