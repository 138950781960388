import { BrowserRouter } from 'react-router-dom'
import { Router, RouterBeforeEach } from '@/router'
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import 'antd/dist/antd.css';
function App() {

  return (
    <div className='container'>
      <ConfigProvider locale={zhCN}>
        <BrowserRouter>
          <RouterBeforeEach>
            <Router />
          </RouterBeforeEach>
        </BrowserRouter>
      </ConfigProvider>
    </div>
  );
}

export default App;
