import './index.scss';
import PhoneIcon from '@/assets/img/ic_dianhua.png';
import MessageIcon from '@/assets/img/ic_lianxiwomen.png';
import SearchIcon from '@/assets/img/ic_sousuo.png';
import { useNavigate } from 'react-router-dom';


export default function MenuFooter({
    openLinkDrawer = () => void 0,
    openHelpDrawer = () => void 0,
    closeDrawer = () => void 0
}) {
    const navigate = useNavigate();

    return (
        <div className="menu_footer">
            <ul className="nav_list">
                <li className="nav_item" onClick={function () {
                    navigate('/search');
                    closeDrawer();
                }}>
                    <img src={SearchIcon} alt="" className="ic" />
                    <span className='name'>搜索</span>
                </li>
                <li className="nav_item" onClick={function () {
                    openLinkDrawer();
                }}>
                    <img src={PhoneIcon} alt="" className="ic" />
                    <span className='name'>联系电话</span>
                </li>
                <li className="nav_item" onClick={function () {
                    openHelpDrawer()
                }}>
                    <img src={MessageIcon} alt="" className="ic" />
                    <span className='name'>客服留言</span>
                </li>
            </ul>
        </div>
    )
}