import './index.scss';
import Footer from '@/components/Footer/Footer';
import Header from '@/components/Header/Header';
import PageContentWidthTitle from '@/components/PageContentWidthTitle/PageContentWidthTitle';
import { useEffect, useState } from 'react';
import { getConfig } from '@/api';



export default function AddressPage() {
    const [hotline, setHotline] = useState('');

    useEffect(() => {
        const map = new window.AMap.Map("map", {
            resizeEnable: true,
            center: [120.38205720866776, 36.090616962155636],//地图中心点
            zoom: 15 //地图显示的缩放级别
        });
        window.AMap.plugin(['AMap.ToolBar', 'AMap.Scale'], function () {
            //工具类-放大缩小
            map.addControl(new window.AMap.ToolBar())
            //比例尺
            map.addControl(new window.AMap.Scale())
            const marker = new window.AMap.Marker({
                icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
                position: [120.37899434566498, 36.09016479349323],
            });
            marker.setMap(map);
        })

        getConfig().then(res => {
            res.data.map(it => {
                if (it.name === "service_hotline") {
                    setHotline(it.value);
                }
            })
        })

    }, [])
    return (
        <div className="address_page">
            <Header />
            <div className="split-line">
                <PageContentWidthTitle title='地图地址'>
                    <>
                        <div className="company_name">
                            青岛瑞加漆业有限公司：
                        </div>

                        <p className="address">
                            青岛市市北区龙城路31号卓越世纪中心
                        </p>

                        <div className="address_img">
                            <div id='map'></div>
                        </div>

                        <div className="link_type">
                            联系电话：
                        </div>

                        <p className="phone_number">
                            {hotline}
                        </p>
                    </>
                </PageContentWidthTitle>
            </div>
            <Footer hideForm />
        </div>
    )
}